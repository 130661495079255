<template>
  <main class="pdfLandscapeContainer padding">
    <div class="header">
      <label v-if="config && config.showTitleHead" class="title">{{ profileUserConfig.enterprise || title }}</label>
      <img v-if="config && config.showLogo"
        src="../../assets/img/logo.jpg" alt="" class="modal-logo" width="150px"
      />
    </div>
    <div v-if="config && config.type === 'document'" class="infoHeader">
      <b-row class="mt-0 pt-0 mb-0 pb-0">
        <b-col cols="8" class="mt-0 pt-0 mb-0 pb-0">
          <span style="font-size: 36px;">{{ profileUserConfig.enterprise || title }}</span>
        </b-col>
      </b-row>
      <b-row class="mb-0 pb-0 mt-0 mt-0">
        <b-col cols="8">
          <span>{{ profileUserConfig.addressEnterprise }}</span>
        </b-col>
        <b-col cols="2">
        </b-col>
        <b-col cols="2">
          <section style="text-align: left;">
            Data: {{ headerData?.date || ""}}
          </section>
        </b-col>
      </b-row>
      <b-row class="mb-0 pb-0 mt-0 mt-0">
        <b-col cols="8">
          <span>{{ profileUserConfig.identityEnterprise }} - {{ profileUserConfig.telephoneEnterprise }}</span>
        </b-col>
        <b-col cols="2">
        </b-col>
        <b-col cols="2">
          <section style="text-align: left;">
            {{ config.titleDocument }}: {{ headerData?.data?.idBudget || ""}}
          </section>
        </b-col>
      </b-row>
      <b-row class="mb-4 mt-0">
        <b-col cols="8">
          <span></span>
        </b-col>
        <b-col cols="1">
        </b-col>
        <b-col cols="3">
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="8">
          <span>Cliente: {{ headerData?.data?.nameCustomer || "-" }}</span>
        </b-col>
        <b-col cols="1">
        </b-col>
        <b-col cols="3">
        </b-col>
      </b-row>
    </div>
    <div v-else class="infoHeader">
      <label>{{ title }} </label>
      <b-row>
        <br />
        <b-col cols="2">
          <label>Data de emissão</label>
          <br />
          <div class="info">
            {{ headerData.date || '-' }}
          </div>
          <br />
        </b-col>
        <b-col cols="2">
          <label>Hora</label>
          <br />
          <div class="info">
            {{ headerData.hour || '-' }}
          </div>
          <br />
        </b-col>
        <b-col cols="8">
          <label>Usuário</label>
          <br />
          <div class="info">
            {{ headerData.user ||'-' }}
          </div>
          <br />
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <div v-for="key in filtersKeys" v-bind:key="key">
            <label>{{`${key}: ${filterData[key]}`}}</label>
            <br/>
          </div>
        </b-col>
        <b-col v-if="filterData && (filterData.initDate || filterData.endDate)">
          <label>Periodo: De {{ filterData.initDate }} até {{ filterData.endDate }}</label>
          <br />
          <label>Total de registros: {{ headerData ? headerData.qtdRows : '-' }}</label>
        </b-col>
      </b-row>
    </div>

    <div>
      <b-table
        striped
        :items="rows"
        :fields="fields"
        :class="fontSize"
      >
        <template #cell(balance)="row">
          <div class="text-right">{{ row.amount }}</div>
        </template>
        <template #cell(renda)="row">
          <div class="text-right">{{ row.incomme }}</div>
        </template>
        <template #cell(despesa)="row">
          <div class="text-right">{{ row.expense }}</div>
        </template>

      </b-table>
    </div>
    <div v-if="config && config.type === 'document'" class="infoHeader">
      <div>
        <b-row><b-col>_________________________________________________________________________________________________________________________________________________________________</b-col></b-row>
        <b-row class="mb-4">
          <b-col cols="8" >
          </b-col>
          <b-col cols="2" >
            <section class="total-style">
              Total:
            </section>
          </b-col>
          <b-col cols="2">
            <section class="pr-3 total-style">
              {{ headerData?.data?.grandTotal || "-"}}
            </section>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row class="mb-2"><b-col>_________________________________________________________________________________________________________________________________________________________________</b-col></b-row>
        <div>
          <span style="font-weight: bold;">Observações: </span><span>{{ headerData?.data?.comments || "" }}</span>
        </div>
        <b-row class="mt-0"><b-col>_________________________________________________________________________________________________________________________________________________________________</b-col></b-row>
      </div>
    </div>

  </main>
</template>

<script>

	export default {
		components: {},

		computed: {
			headers () {
				return this.rows.length && Object.keys(this.rows[0]);
			},
			filtersKeys () {
				return Object.keys(this.filterData).filter((key) => key !== "initDate" && key !== "endDate");
			},
			fontSize () {
				if (!this.headers || !this.headers.length) return "fs-16";
				const cols = this.headers.length;
				if (cols <= 6) return "fs-16";
				if (cols <= 8) return "fs-14";
				if (cols <= 10) return "fs-12";
				if (cols <= 12) return "fs-10";
				return "fs-9";
			}
		},

		props: {
			headerData: {
				type: Object,
				default () {
					return {};
				}
			},
			filterData: {
				type: Object,
				default () {
					return {};
				}
			},
			rows: {
				type: Array
			},
			title: {
				type: String,
				default: ""
			},
			fields: {
				type: Array
			},
			config: {
				type: Object,
				require: false
			}
		},

		data () {
			return {
				profileUserConfig: {}
			};
		},

		mounted () {
			this.profileUserConfig = localStorage.params ? JSON.parse(localStorage.params) : {};
		}
	};
</script>

<style scoped>
.total-style {
  text-align: right;
  font-size: 20px;
  font-weight: bold;
}
.column-width {
  /* max-width: 100px; */
  overflow: hidden;
}

* {
  font-weight: 400;
  font-size: 16px;
}

.fs-9 {
  font-size: 9px;
}
.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}

.subTitle {
  font-weight: 600;
}

.pdfLandscapeContainer {
  width: 842pt;
}

.padding {
  padding: 36px;
  font-weight: 400;
}

label {
  margin-bottom: 3px;
}

.info {
  margin-bottom: 12px;
}

.underline {
  flex-grow: 1;
  /* width: 100%; */
  border-bottom: 1px solid black;
  margin-left: 5px;
  margin: 0 10px 4px 5px;
}

.div-assinaturas {
  display: flex;
  margin-bottom: 10px;
}

.infoHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.infoHeader h2 {
  font-size: 16px;
  font-weight: 600;
}

.modal-header {
  border-bottom: 0;
}

.header .modal-logo {
  position: absolute;
  right: 20px;
  top: 20px;
  margin-top: 10px;
}

.header .title {
  font-weight: 500;
  font-size: 18px;
  margin-top: -30px;
  margin-bottom: 40px;
  margin-left: -10px;
}

</style>
